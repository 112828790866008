import React, { useState, useRef, useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { ArrowUpwardRounded } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import ChatMessage from "./ChatMessage";
import { Avatar, CircularProgress, Input, Typography } from "@mui/material";
import icon from "../media/chatbot/chatbot.png";
import useChatRetrieveInfiniteQuery from "../hooks/useChatRetrieveInfiniteQuery";
import { useInView } from "react-intersection-observer";
import { useChatMutations } from "../hooks/useChatMutations";
import { useChatbotEffects } from "../hooks/useChatbotEffects";

const placeholders = [
  "Suggest a top-rated food tour in Rome.",
  "Any family-friendly activities in New York?",
  "Can you recommend a desert safari in Dubai?",
  "Suggest a night market tour in Bangkok",
  "I want a budget-friendly city tour in Tokyo.",
];
const ChatBoxModal = React.memo(({ open, onClose }) => {
  const session = undefined;
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const [message, setMessage] = useState("");

  const chatContainerRef = useRef(null);
  const prevScrollHeightRef = useRef(0);

  const { data: chatMessages } = useQuery({
    queryKey: ["chatMessages"],
    staleTime: Infinity,
    cacheTime: Infinity,
    gcTime: Infinity,
  });
  const {
    data: dbConversation,
    loadMoreMessages,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    error,
  } = useChatRetrieveInfiniteQuery();
  const { userMutation, updateMessagesInCache, serverMutation } =
    useChatMutations();

  const handleSendMessage = () => {
    const trimmedMessage = message.trim();
    if (trimmedMessage) {
      updateMessagesInCache({ text: trimmedMessage, user: true });
      if (session) userMutation.mutate(trimmedMessage);
      serverMutation.mutate({ message: trimmedMessage });
      setMessage("");
      setTimeout(scrollToBottom, 10); //timeout for ensuring DOM to be available before scrolling
    }
  };
  const handleInputKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const scrollToBottom = useCallback(() => {
    if (chatContainerRef.current) {
      const container = chatContainerRef.current;
      container.scrollTop = container.scrollHeight - container.clientHeight;
    }
  }, []);

  // all the effects are in this hook
  const { placeholderIndex } = useChatbotEffects({
    open,
    inView,
    isFetching,
    hasNextPage,
    error,
    loadMoreMessages,
    chatContainerRef,
    prevScrollHeightRef,
    chatMessages,
    dbConversation,
    scrollToBottom,
  });

  return (
    <Dialog
      open={open}
      onClose={() => {}}
      disableBackdropClick
      scroll="paper"
      maxWidth="xs"
      PaperProps={{
        style: {
          ...dialogStyle,
          height: !chatMessages ? "50vh" : "65vh",
          borderRadius: ".8em",
        },
        elevation: 5,
      }}
      BackdropProps={{
        style: {
          backgroundColor: "transparent",
        },
      }}
    >
      <DialogContent style={contentContainerStyle} ref={chatContainerRef}>
        {!chatMessages ? (
          <div style={headMessageStyle}>
            <div style={{ margin: "0 auto .8em", display: "table" }}>
              <Avatar {...avatarStyle} />
            </div>
            <Typography variant="subheading1">
              {`Welcome! I'm here to help you find your perfect tour or activity.
              What are you looking for and where?`}
            </Typography>
          </div>
        ) : (
          <>
            <div
              style={{
                margin: "auto",
              }}
              ref={ref} //ref of inview trigger
            >
              {(isFetching || isFetchingNextPage || isLoading) &&
              hasNextPage ? (
                <CircularProgress
                  size={30}
                  style={{ marginTop: -10, marginBottom: 5, color: "#EC5327" }}
                />
              ) : (
                error && (
                  <Typography variant="subheading1">
                    Couldnot load previous conversation
                  </Typography>
                )
              )}
            </div>
            <ChatMessage
              message={chatMessages}
              mutation={serverMutation}
              dbConversation={dbConversation?.pages}
            />
          </>
        )}
      </DialogContent>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Paper component="form" sx={paperStyle} style={{ padding: ".7em" }}>
          <Input
            disableUnderline
            sx={{
              ...inputStyle,
              "& input": {
                border: "none",
                outline: "none",
              },
            }}
            placeholder={!chatMessages && placeholders[placeholderIndex]}
            inputProps={{ "aria-label": "Queries" }}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleInputKeyDown}
            disabled={serverMutation.isPending}
          />
          <IconButton
            color="primary"
            sx={sendButtonStyle}
            aria-label="send"
            onClick={handleSendMessage}
          >
            <ArrowUpwardRounded />
          </IconButton>
        </Paper>
      </div>
    </Dialog>
  );
});

export default ChatBoxModal;

const dialogStyle = {
  bottom: 120,
  right: 0,
  margin: 0,
  maxHeight: "calc(70vh - 48px)",
  position: "fixed",
  width: "100%",
  "@media (min-width: 600px)": {
    width: "auto",
    maxWidth: "80%",
  },
};
const avatarStyle = {
  src: icon,
  alt: "Bot Icon",
  sx: {
    width: "3em",
    height: "3em",
  },
};

const contentContainerStyle = {
  display: "flex",
  flexDirection: "column",
  maxHeight: "65vh",
  height: "50vh",
};

const paperStyle = {
  p: "0.375em 0.5em",
  display: "flex",
  border: 0,

  boxShadow: "none",
  alignItems: "center",
  justifyContent: "space-around",
  width: "100%",
};

const inputStyle = {
  backgroundColor: "#EEEEEE",
  borderRadius: "1.875em",
  padding: "0.2em",
  width: "85%",
  paddingLeft: "0.625em",
};

const sendButtonStyle = {
  p: "0.3125em",
  backgroundColor: "rgb(235, 94, 40)",
  color: "white",
  transition: "background-color 0.3s ease",
  "&:hover": {
    backgroundColor: "#EEEEEE",
  },
};

const headMessageStyle = {
  textAlign: "center",
  cursor: "pointer",
};
