import { useMutation, useQueryClient } from "@tanstack/react-query";
import { httpClient } from "../httpClient/index";

export const useChatMutations = () => {
  console.log(process.env.NEXT_PUBLIC_CHAT_API_BASE_URL);
  const queryClient = useQueryClient();
  const session = undefined;
  //To update chat's in cache
  const updateMessagesInCache = (newMessage) => {
    queryClient.setQueryData(["chatMessages"], (oldMessages) => {
      return [...(oldMessages || []), newMessage];
    });
  };
  //Mutation for user
  const userMutation = useMutation({
    mutationFn: (message) =>
      httpClient(`https://api.tourteller.com/app/chat/`, {
        method: "POST",
        data: {
          message: message,
          is_from_bot: false,
        },
      }),
  });
  //mutation for bot
  const botMutation = useMutation({
    mutationFn: (botMessage) =>
      httpClient(`https://api.tourteller.com/app/chat/`, {
        method: "POST",
        data: {
          message: botMessage.text.toString(),
          is_from_bot: true,
          tour_data_from_bot: botMessage?.destination || [],
          data_fetch_status: botMessage?.attachment?.data_fetch_status,
          bot_response_type: botMessage?.type,
        },
      }),
  });
  //mutation to chatbot server
  const serverMutation = useMutation({
    mutationFn: (query) =>
      httpClient(
        `https://chatbotapi.tourteller.com/webhooks/rest/webhook`,
        {
          method: "POST",
          data: {
            message: query.message,
          },
        },
        { useAuth: false }
      ),
    onSuccess: (data) => {
      const botMessage = {
        text: data?.data?.[0]?.attachment?.query_response,
        destination: data?.data?.[0].attachment?.data,
        user: false,
        type: data?.data?.[0]?.attachment?.type,
      };
      updateMessagesInCache(botMessage);
      if (session) botMutation.mutate(botMessage);
      else publicBotMutation.mutate(data);
    },
    onError: () => {
      const botMessage = {
        text: "Oops! Looks like I hit a snag. Could you try rephrasing your question?",
        destination: [],
        user: false,
        type: "normal_message",
      };
      updateMessagesInCache(botMessage);
      if (session) botMutation.mutate(botMessage);
      else publicBotMutation.mutate(botMessage);
    },
  });

  //public/non-logged bot's mutation
  const publicBotMutation = useMutation({
    mutationFn: (botMessage) =>
      httpClient(
        `https://api.tourteller.com/app/public/save`,
        {
          method: "POST",
          data: {
            ...botMessage,
          },
        },
        { useAuth: false }
      ),
  });
  return {
    userMutation,
    botMutation,
    serverMutation,
    publicBotMutation,
    updateMessagesInCache,
  };
};
