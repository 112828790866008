import React, { useState } from "react";
import ChatBoxModal from "./Chatbot/ChatBoxModal.jsx";

const App = () => {
  const [openModal, setOpenModal] = useState(true);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      {openModal && (
        <ChatBoxModal open={openModal} onClose={handleCloseModal} />
      )}
    </>
  );
};

export default App;
