import { Avatar, Grid } from "@mui/material";
import icon from "../media/chatbot/chatbot.png";

const botAvatarStyle = {
  src: icon,
  alt: "Torch",
};
const BotAvatar = () => {
  return (
    <Grid item mb={1} xs={8} style={{ display: "flex", alignItems: "center" }}>
      <Avatar
        sx={{ height: "25px", width: "25px" }}
        src={botAvatarStyle.src}
        alt={botAvatarStyle.alt}
      />
      <span style={{ fontSize: "0.75em", paddingLeft: "0.3em" }}>Torch</span>
    </Grid>
  );
};
export default BotAvatar;
