"use client";
import React from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import BotAvatar from "./BotAvatar";
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/swiper-bundle.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import DestinationCard from "./DestinationCard";
import { v4 as uuid } from "uuid";

const ChatMessage = React.memo(({ message, mutation, dbConversation }) => {
  let normalizeDbConversation = dbConversation
    ?.flatMap((dbConv) => dbConv?.data?.conversation)
    ?.filter((message, index, self) => {
      return index === self.findIndex((m) => m.timestamp === message.timestamp);
    })
    ?.map((message) => {
      return {
        text: message?.message,
        user: !message?.is_from_bot,
        type: message?.bot_response_type || "normal_message",
        destination: message?.tour_data_from_bot,
        timestamp: message?.timestamp,
      };
    })
    ?.sort(
      (parent, child) =>
        new Date(parent?.timestamp) - new Date(child?.timestamp)
    );
  const combinedMessages = [
    ...(normalizeDbConversation || []),
    ...(message || []),
  ];
  const isSmallScreen340 = useMediaQuery("(max-width:340px)");
  const isSmallScreen420 = useMediaQuery("(max-width:420px)");
  let slidesPerView = 2.15;
  if (isSmallScreen340) {
    slidesPerView = 1.5;
  } else if (isSmallScreen420) {
    slidesPerView = 1.7;
  }
  return (
    <div style={chatContainerStyle} id="chat-container">
      {combinedMessages?.map((msg) => (
        <Grid
          container
          key={uuid()}
          justifyContent={msg.user ? "flex-end" : "flex-start"}
          alignItems="flex-start"
          spacing={0.03125}
          style={messageContainerStyle}
        >
          {!msg.user && <BotAvatar />}
          <Grid item xs={11}>
            <div style={getMessageContainerStyle(msg.user)}>
              {msg?.text && typeof msg?.text === "string" && (
                <div>{parse(msg.text.split("\t")[0])}</div>
              )}
              {msg?.type === "message_with_lists" &&
                msg?.destination?.map((list) => (
                  <li key={uuid()}>{list?.name}</li>
                ))}
              {msg?.destination &&
                Array.isArray(msg?.destination) &&
                msg?.type !== "message_with_lists" &&
                msg?.destination.map((product, key) => (
                  <div key={uuid()}>
                    <div style={imageStyle}>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => window.open(product?.url)}
                      >
                        <Typography
                          color={"rgb(235, 94, 40)"}
                          variant="subheading1"
                          style={pStyle}
                        >
                          {key + 1}. {product.name}
                        </Typography>
                      </div>
                      <div style={{ cursor: "pointer" }}>
                        <img
                          height={"120px"}
                          onClick={() => window.open(product?.url)}
                          src={product.image_big_landscape}
                          alt={product.name}
                          style={imageStyle}
                        />
                      </div>
                      <p style={pStyle}>
                        - Price: US ${" "}
                        {product.price !== null ? product.price : " N/A"}
                      </p>
                      <p style={pStyle}>
                        - Rating / Review:{" "}
                        {product.rating !== null ? product.rating : " N/A"} /{" "}
                        {product.review_count !== null
                          ? product.review_count
                          : "N/A"}
                      </p>
                      <p style={pStyle}>
                        - Duration:
                        {product.duration !== null
                          ? product.duration < 60
                            ? ` ${product.duration} min`
                            : ` ${product.duration / 60} hr`
                          : " N/A"}
                      </p>
                      <p style={pStyle}>
                        - Provider:{" "}
                        {product.ota_name !== null ? product.ota_name : " N/A"}
                      </p>
                      <p style={pStyle}>
                        - Description:
                        {(msg?.type !== "message_with_lists" &&
                          parse(
                            product && product?.summary?.length > 200
                              ? ` ${product?.summary.substring(0, 200)}... `
                              : product?.summary
                          )) ||
                          ""}
                      </p>
                    </div>
                  </div>
                ))}
              {msg.text &&
                typeof msg.text === "string" &&
                msg.text.split("\t").slice(1).join("\t") && (
                  <>
                    {parse(
                      msg?.text?.toString()?.split("\t").slice(1).join("\n")
                    ) || ""}
                  </>
                )}
            </div>
          </Grid>
          {msg?.destination !== undefined &&
            msg?.type !== "message_with_lists" &&
            msg?.destination?.length > 4 && (
              <Swiper
                spaceBetween={15}
                slidesPerView={slidesPerView}
                style={{ marginTop: "20px" }}
              >
                {msg?.destination?.map((product, index) => (
                  <>
                    <SwiperSlide key={index}>
                      <DestinationCard
                        title={product.name}
                        rating={product.rating}
                        rating_number={product.review_count}
                        price={product.price}
                        image={product.image_big_landscape}
                        url={product.url}
                      />
                    </SwiperSlide>
                  </>
                ))}
              </Swiper>
            )}
        </Grid>
      ))}

      {mutation?.isPending && (
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          spacing={0.03125}
          style={messageContainerStyle}
        >
          <BotAvatar />
          <Grid item xs={11}>
            <div
              style={{
                ...getMessageContainerStyle(false),
                ...thinkingMessageStyle,
              }}
              className="thinking-message"
            >
              Torch is thinking...🤔
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
});

ChatMessage.displayName = "ChatMessage";

export default ChatMessage;
const chatContainerStyle = {
  flex: 1,
};
const messageContainerStyle = {
  marginBottom: "1em",
};

const pStyle = {
  margin: "0.5em 0",
  fontWeight: "400",
};

const imageStyle = {
  paddingTop: "0.9375em",
  paddingBottom: "0.9375em",
  width: "100%",
  objectFit: "cover",
};

const getMessageContainerStyle = (user) => ({
  position: "relative",
  marginLeft: user ? "0" : "1em",
  marginRight: user ? "1em" : "0",
  backgroundColor: user ? "rgb(235, 94, 40)" : "#EEEEEE",
  padding: "0.8em",
  minHeight: "2em",
  borderRadius: "1.3em",
  color: user ? "#FFFFFF" : "#000000",
  wordWrap: "break-word break-all",
  whiteSpace: "pre-wrap",
  boxShadow: "-0.1875em 0.5em 3.8125em -1.5625em rgba(63,58,58,0.75)",
});

const thinkingMessageStyle = {
  display: "flex",
  alignItems: "center",
};
