import axios from "axios";

export async function httpClient(url, options = {}, useAuth = true) {
  const headers = {
    ...options.headers,
  };

  if (useAuth) {
    const session = undefined;
    if (session?.apiToken) {
      headers.Authorization = `Bearer ${session.apiToken}`;
    }
  }

  try {
    const response = await axios({
      url,
      headers,
      ...options,
    });

    return response;
  } catch (error) {
    // Handle errors appropriately
    throw error;
  }
}
