import { useEffect, useLayoutEffect, useCallback, useState } from 'react'

export const useChatbotEffects = ({
  open,
  inView,
  isFetching,
  hasNextPage,
  error,
  loadMoreMessages,
  chatContainerRef,
  prevScrollHeightRef,
  chatMessages,
  dbConversation,
  scrollToBottom,
}) => {
  const [placeholderIndex, setPlaceholderIndex] = useState(0)

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        scrollToBottom()
      }, 0)
    }
  }, [open, scrollToBottom])

  useEffect(() => {
    if (inView && !isFetching && hasNextPage && !error) {
      loadMoreMessages()
    }
  }, [inView, isFetching, hasNextPage, error, loadMoreMessages])

  useEffect(() => {
    const container = chatContainerRef.current
    if (!container) return

    const shouldScroll =
      prevScrollHeightRef.current &&
      container.scrollTop + container.clientHeight >=
        prevScrollHeightRef.current
    if (shouldScroll) {
      scrollToBottom()
    }
    prevScrollHeightRef.current = container.scrollHeight
  }, [chatMessages, scrollToBottom, chatContainerRef, prevScrollHeightRef])

  const isUserAtBottom = useCallback(() => {
    const { scrollTop, clientHeight, scrollHeight } =
      chatContainerRef.current || {}
    const tolerance = 5
    return scrollTop + clientHeight + tolerance >= scrollHeight
  }, [chatContainerRef])

  useLayoutEffect(() => {
    requestAnimationFrame(() => {
      if (chatContainerRef.current) {
        const wasAtBottom = isUserAtBottom()
        const currentScrollHeight = chatContainerRef.current.scrollHeight
        const scrollHeightBeforeUpdate = prevScrollHeightRef.current
        const newMessageHeight = currentScrollHeight - scrollHeightBeforeUpdate

        if (wasAtBottom) {
          chatContainerRef.current.scrollTop = currentScrollHeight
        } else {
          chatContainerRef.current.scrollTop += newMessageHeight
        }

        prevScrollHeightRef.current = currentScrollHeight
      }
    })
  }, [dbConversation, isUserAtBottom, chatContainerRef, prevScrollHeightRef])
  useEffect(() => {
    const interval = setInterval(() => {
      if (!chatMessages) {
        setPlaceholderIndex((prevIndex) => (prevIndex + 1) % 5)
      }
    }, 1500)
    return () => clearInterval(interval)
  }, [chatMessages])
  return {
    placeholderIndex,
  }
}
