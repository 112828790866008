import { httpClient } from "../httpClient/index";
import { useInfiniteQuery } from "@tanstack/react-query";

const useChatRetrieveInfiniteQuery = () => {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isLoading,
    error,
    isFetched,
  } = useInfiniteQuery({
    queryKey: ["infinteChat"],
    enabled: false,
    staleTime: Infinity,
    cacheTime: Infinity,
    gcTime: Infinity,

    queryFn: ({ pageParam = 1 }) =>
      httpClient(
        `${process.env.NEXT_PUBLIC_TT_API_ORIGIN}/app/chat/retrieve/me?page=${pageParam}&size=10`,
        { method: "GET" }
      ),
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.data?.next ? allPages?.length + 1 : undefined;
    },
  });

  const loadMoreMessages = () => {
    if (hasNextPage && !isFetchingNextPage && !isFetching) {
      fetchNextPage();
    }
  };

  return {
    data,
    loadMoreMessages,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isLoading,
    error,
    isFetched,
  };
};

export default useChatRetrieveInfiniteQuery;
