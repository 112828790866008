'use client'
import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import CardActionArea from '@mui/material/CardActionArea'
import { StarHalfTwoTone } from '@mui/icons-material'

const DestinationCard = ({
  title,
  rating,
  rating_number,
  price,
  image,
  url,
}) => {
  return (
    <Card style={cardStyle}>
      <CardActionArea onClick={() => window.open(url)}>
        <CardMedia
          style={mediaStyle}
          component="img"
          image={image}
          alt="Product card"
        />
        <CardContent style={contentStyle}>
          <Typography variant="h2" style={headingStyle}>
            {title}
          </Typography>
          <div style={flexContainer}>
            <div style={ratingStyle}>
              <StarHalfTwoTone style={starIconStyle} />
              <span style={{ fontSize: '.9rem', color: 'grey' }}>
                <strong style={{ color: 'black' }}>
                  {rating !== null ? rating : 'N/A'}
                </strong>
                {`(${rating_number !== null ? rating_number : 'N/A'})`}
              </span>
            </div>
            <Typography
              style={{ fontWeight: 'bold', color: 'rgb(235, 94, 40)' }}
              variant="body1"
            >
              ${price}
            </Typography>
          </div>
          <Button style={buttonStyle} variant="contained">
            View Deal
          </Button>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default DestinationCard
const cardStyle = {
  width: 180,
  margin: 'auto',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#fff',
}

const mediaStyle = {
  height: 100,
}

const contentStyle = {
  padding: '6px',
  textAlign: 'center',
}

const buttonStyle = {
  background: 'rgb(235, 94, 40)',
  color: '#fff',
  marginTop: '5px',
  width: '100%',
}

const headingStyle = {
  fontWeight: '600',
  fontSize: '1.1rem',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  minHeight: '3rem',
  whiteSpace: 'normal',
  lineHeight: '1.5rem',
}

const flexContainer = {
  marginTop: '.3rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}

const ratingStyle = {
  display: 'flex',
  alignItems: 'center',
}

const starIconStyle = {
  fontSize: '1.3rem',
  verticalAlign: 'middle',
}
